
html {
    font-size: 62.5%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
    font-family: 'Anonymous Pro';
}

main {
    display: flex;
    align-items: center;
    flex-flow: column;

    padding-top: 5rem;
}

p {
    font-size: 2rem;
    line-height: 3rem;
}

form {
    position: relative;
    width: 60rem;

}

.formDiv {
    width: 60rem;
    margin: 5rem;
    margin-top: 15rem;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column;
}

.sliderDiv {
    width: 50rem;
    margin: 5rem;
    position: absolute;
    top: 0;
    left: 0;
}

.logo {
    margin: 0 auto;
}

input {
    outline: 0;
    border-width: 0 0 0.5px;
    border-color: black;
    font-size: 1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

input:focus {
    border-color: #FC464A;
}

.subForm {
    width: 60rem;
    display: flex;
    margin-bottom: 5rem;
}

.firstButton {
    color: #FC464A;
    font-size: 1.6rem;
    font-weight: bolder;
    font-family: 'Open Sans';
    margin-top: 15rem;
    text-decoration: none;
    margin-left: -15rem;
    text-transform: uppercase;

}

.submitButton {
    color: #FC464A;
    font-size: 1.6rem;
    font-weight: bolder;
    font-family: 'Open Sans';
    text-decoration: none;
    text-transform: uppercase;

    position: fixed;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 200px;

    background: none;
    border: none;
}

.valueSpan {
    font-size: 3rem;
    font-weight: bolder;
    opacity: 1;
}

.value {
    padding-top: 3rem;
    font-size: 1.6rem;
    text-align: center;
}

.val {
    position: absolute;
}

.dearTechTitle {
    padding-bottom: 6rem;
    max-width: 270px;
    text-align: center;
    margin: auto;
}

.allQuotes {
    display: flex;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap;
}

@-webkit-keyframes fadeinout {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.miniQuote {
    width: 30rem;
    padding: 3rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    line-height: 3rem;
    margin-bottom: 3rem;
}

.miniQuote-fixed {
    position: fixed;
}

.miniQuote-0 {
    left: calc(20vw - 15rem);
    top: 10vh;
}

.miniQuote-1 {
    left: calc(50vw - 15rem);
    top: 10vh;
}

.miniQuote-2 {
    left: calc(80vw - 15rem);
    top: 10vh;
}

.miniQuote-3 {
    left: calc(20vw - 15rem);
    top: 50vh;
}

.miniQuote-4 {
    left: calc(50vw - 15rem);
    top: 50vh;
}

.miniQuote-5 {
    left: calc(80vw - 15rem);
    top: 50vh;
}

.miniQuote p {
    padding-bottom: 1rem;
}

.galleryQuote p {
    width: 60rem;
    font-size: 2.8rem;
    margin-top: 10rem;
    line-height: 5rem;
    text-align: center;
}

nav {
    margin-left: 12rem;
    display: flex;
    justify-content: center;
}

/*SLIDER*/

.rc-slider {
    position: relative;
    height: 14px;
    padding: 5px 0;
    width: 100%;
    border-radius: 6px;
    -ms-touch-action: none;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-rail {
    position: absolute;
    width: 100%;
    background-color: #e9e9e9;
    height: 4px;
    border-radius: 6px;
}

.rc-slider-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: 6px;
    background-color: #FC464A;
}

.rc-slider-handle {
    position: absolute;
    margin-left: -7px;
    margin-top: -5px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #FC464A;
    background-color: #fff;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
}

.rc-slider-handle:focus {
    border-color: #FC464A;
    box-shadow: 0 0 0 5px #FC464A;
    outline: none;
}

.rc-slider-handle-click-focused:focus {
    border-color: #FC464A;
    box-shadow: unset;
}

.rc-slider-handle:hover {
    border-color: #FC464A;
}

.rc-slider-handle:active {
    border-color: #FC464A;
    box-shadow: 0 0 5px #FC464A;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
}

.rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;
}

.rc-slider-mark-text-active {
    color: #666;
}

.rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
}

.rc-slider-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
}

.rc-slider-dot-active {
    border-color: #96dbfa;
}

.rc-slider-disabled {
    background-color: #e9e9e9;
}

.rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
}

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
    border-color: #ccc;
    box-shadow: none;
    background-color: #fff;
    cursor: not-allowed;
}

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
}

.rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
}

.rc-slider-vertical .rc-slider-rail {
    height: 100%;
    width: 4px;
}

.rc-slider-vertical .rc-slider-track {
    left: 5px;
    bottom: 0;
    width: 4px;
}

.rc-slider-vertical .rc-slider-handle {
    margin-left: -5px;
    margin-bottom: -7px;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
}

.rc-slider-vertical .rc-slider-step {
    height: 100%;
    width: 4px;
}

.rc-slider-vertical .rc-slider-dot {
    left: 2px;
    margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:first-child {
    margin-bottom: -4px;
}

.rc-slider-vertical .rc-slider-dot:last-child {
    margin-bottom: -4px;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    animation-duration: .3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-leave {
    animation-duration: .3s;
    animation-fill-mode: both;
    display: block !important;
    animation-play-state: paused;
}

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
    animation-name: rcSliderTooltipZoomDownIn;
    animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
    animation-name: rcSliderTooltipZoomDownOut;
    animation-play-state: running;
}

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
    transform: scale(0, 0);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.rc-slider-tooltip-zoom-down-leave {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes rcSliderTooltipZoomDownIn {
    0% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
    }
    100% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
}

@keyframes rcSliderTooltipZoomDownOut {
    0% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
    100% {
        opacity: 0;
        transform-origin: 50% 100%;
        transform: scale(0, 0);
    }
}

.rc-slider-tooltip {
    position: absolute;
    left: -9999px;
    top: -9999px;
    visibility: visible;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider-tooltip-hidden {
    display: none;
}

.rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
}

.rc-slider-tooltip-inner {
    padding: 6px 2px;
    min-width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    text-align: center;
    text-decoration: none;
    background-color: #6c6c6c;
    border-radius: 6px;
    box-shadow: 0 0 4px #d9d9d9;
}

.rc-slider-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    bottom: 4px;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-top-color: #6c6c6c;
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 500ms ease-in;
}